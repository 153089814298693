import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import format from 'date-fns/format'

import Breadcrumbs from "@components/common/Breadcrumbs"
import Card from "@components/common/Card"

const breadcrumbs = [
  {text: 'Top', to: '/'},
  {text: 'コラム'},
];

const Main = props => {
  return (
    <Container>
      <Breadcrumbs data={breadcrumbs} />
      <Title>新着順で表示</Title>
      <Body>
        {props.data.map((elem, index) => {
          const node = elem.node;
          return (
            <Card
              src={node.hero_image.url}
              title={node.title}
              createdAt={format(new Date(node.publishedAt), 'yyyy.MM.dd')}
              onClick={() => navigate(`/blog/${node.blogId}/`)}
              titleComponent='h3'
              key={`card${index + 1}`}
            />
          )
        })}
      </Body>
    </Container>
  )
}

const Container = styled.div`
  padding: 0px 24px;
`;

const Title = styled.h2`
  margin-top: 10px;
  display: block;
  font-size: 12px;
  line-height: 1.5;
  color: #333333;
`;

const Body = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
`;

export default Main
